@import "~@carfax-stencils/styles/src/colors.scss";

.invite-user-header-title {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.invite-user-modal-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px;
}

.invite-user-separator-line {
  width: 100%;
  color: $grey-light;
  border-top: solid;
  border-left: solid;
  border-width: thin;
  margin-top: 20px;
}

.invite-user-info-role-name {
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.invite-user-info-label {
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.invite-user-info {
  height: 750px;
  padding: 25px;

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }

  &__first {
    padding-right: 24px;
    width: 50%;
  }

  &__last {
    width: 50%;
  }

  &__email {
    width: 100%;
  }

  &__phone {
    width: 30%;
  }

  &__todo {
    margin-top: 10px;
    padding-left: 75px;
  }

  &__message {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 15px;
  }
  &__oval {
    //padding-left: 11px;
    padding-top: 3px;
    width: 32px;
    height:32px;
    background: $blue;
    color: $white;
    border-radius: 50%;
  }
  &__step-text {
    color: $blue;
    padding-left: 20px;
    padding-top: 3px;
  }

  &__section-heading-text {
    padding-top: 15px;
    padding-bottom: 40px;
    height: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  &__checkbox-input-div{
    padding-bottom: 20px;
  }

}

.invite-user-location {
  height: 750px;
  padding: 25px;
}

.invite-user-confirmation{
  padding: 20px;

  &__title {
    padding-top: 10px;
    padding-bottom: 15px;
  }

  &__details {
    font-size: 14px;
    padding: 20px;
    border: 1px solid $grey;
    background-color: $grey-lightest
  }

  &__column1 {
    padding-right: 30px;
  }
  &__column2 {

  }

}

.label-required {
  color: $red;
}

.location-search-icon {
  color: $blue;
  margin-right: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}

.invite-user-location-search {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 20px;
}

.location-search-text-input {
  width: 400px;
}

.invite-user-location-search-label {
  padding-top: 8px;
  padding-left: 15px;
  font-size: 14px;
}

.invite-user-location-search-count {
  padding-left: 5px;
}

.dealer-status-active {
  margin-top: 6px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: $green;
  padding-right: 10px;
}

.dealer-status-inactive {
  margin-top: 6px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: $grey-dark;
  padding-right: 10px;
}

.location-status {
  border-radius: 20px;
  display: flex;
}

.location-status-text {
  padding-left: 10px;
}