@import "~@carfax-stencils/styles/src/colors.scss";

.audit-history-modal {

  height: 80%;

  &__header {
    font-size: large;
    font-weight: bold;
  }

  &__heading {
    font-weight: bold;
    font-size: 17px;
    padding-bottom: 15px;
  }

  &__subheading {
    font-size: 15px;
    padding-bottom: 15px;
  }

  &__section {
    margin-top: -15px;
  }

  &__label {
    font-size: 15px;
    width: 110px;
  }

  .filters-div-row {
    display: inline-flex;
    padding-bottom: 25px;
  }

  .filter-input-user-div {
    padding-right: 10px;
  }

  .seperator-line {
    margin-bottom: 20px;
  }
}

.audit-history-table {

  &__heading {
    background-color: $grey-lightest;
    border-top: $grey;
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom: $grey;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    font-weight: normal;

    &__TH {
      padding-top: 12px;
      padding-bottom: 12px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 15px;
      font-weight: normal;
    }
  }

  &__column {
    border-top: $grey;
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom: $grey;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 18px 20px 18px 15px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 15px;
    word-break: break-word;
  }
}

.action-id-approved {
  color: $green;
  font-weight: bold;
}

.action-id-rejected {
  color: $red;
  font-weight: bold;
}

.field-name-auto-complete-input {

  &__browser {
    width: 350px;
  }

  &__ipad {
    width: 245px;
  }

}

.paginator-div-top {
  padding-top: 80px;
  padding-bottom: 20px;
  display: inline-flex;
  float: right;
}

.paginator-div-bottom {
  padding-top: 20px;
  padding-bottom: 20px;
  display: inline-flex;
  float: right;
}
