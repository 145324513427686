@import "../../../node_modules/@carfax-stencils/styles/src/colors";
@import "../../../node_modules/@carfax-stencils/styles/src/typography";

.search-page-content-div {
  background-color: $white;
  border: 1px solid #e0e0e0;
  border-bottom-color: #bdbdbd;
  border-radius: 5px;
  flex-basis: 20%;
  margin-left: 24px;
  margin-right: 24px;
  padding-bottom: 20px;
  padding-top: 20px;
  height: fit-content;
}

.search-form {
  padding-top: 20px;
}

.search-heading {
  font-weight: bold;
  font-size: 13px;
  margin-left: 21px;
  padding-bottom: 5px;
}

.search-icon-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.search-inline-error-message-div {
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.search-layout {
  background-color: $white;
  flex-basis: 20%;
  margin-bottom: 10px;
  margin-right: 24px;
  padding-top: 5px;
}

.search-layout-input {
  padding-left: 20px;
  padding-right: 20px;
}

.search-select-input-div {
  padding-left: 20px;
}

.search-text-input {
  width: 100%;
  padding-left: 20px;
}