@import "../../../../node_modules/@carfax-stencils/styles/src/colors";
@import "../../../../node_modules/@carfax-stencils/styles/src/typography";

.legacy-acct-mgmt-tab-div {
  background-color: $white;
  flex-basis: 80%;
  height: 100%;
  margin-bottom: 10px;
  margin-right: 24px;
  padding-right: 5px;
  padding-top: 30px;
}

.legacy-acct-mgmt-tab-layout {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;
  padding-bottom: 110px;
  height: 100%;
}

.legacy-acct-mgmt-heading-section {
  display: flex;
  justify-content: space-between;
}

.legacy-acct-mgmt-button-section {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
}

.legacy-acct-mgmt-cname-toggle {
  padding-right: 10px;
}

.legacy-acct-mgmt-cname-last-updated {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  padding-right: 10px;
  font-size: small;
  font-weight: bold;
}

.legacy-acct-mgmt-users-table {

  &__heading {
    background-color: $grey-lightest;
    border-top: $grey;
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom: $grey;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    font-weight: normal;

    &__TH {
      padding-top: 12px;
      padding-bottom: 12px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 15px;
      font-weight: normal;
    }
  }

  &__column {
    border-top: $grey;
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom: $grey;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    word-break: break-word;
  }
}

.legacy-user-status-bullet {
  font-size: 11px;
  padding-right: 5px;
  svg {
    fill: currentcolor;
    height: 11px;
    width: 11px;
    transform: translateY(-2px);
  }
}

.legacy-user-status-cell {
    background-color: #edf6fc;
    width: 110px;
    height: 30px;
    padding-left: 10px;
    padding-top: 1%;
    border-radius: 20px;
    display: flex;
}

.legacy-user-status-text {
  padding-left: 10px;
  white-space: nowrap;
}

.legacy-user-status-active {
  margin-top: 6px;
  background-color: $green;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.legacy-user-status-inactive {
  margin-top: 6px;
  background-color: $grey-dark;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

@keyframes scaleUp {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.legacy-acct-mgmt-ellipses-menu {
  display: inline-block;
  position: relative;

  .button {
    height: 48px;
    padding: 14px 16px 15px 16px;

    &[data-theme*="white-"] {
      outline-color: $white;
    }
  }
}

.legacy-acct-mgmt-ellipses-menu.popover {
  padding: 0px;
}

.legacy-acct-mgmt-ellipses-menu-list {
  cursor: pointer;
  max-height: 280px;
  max-width: 600px;
  overflow-y: auto;
}

.legacy-acct-mgmt-ellipses-menu-list-item {
  @extend %text-body-large;
  background-color: $white;
  padding: 8px 16px;
  transition: background-color 0.2s;
  font-size: 14px;

  &:first-child {
    padding-top: 8px;
  }
  &:last-child {
    padding-bottom: 8px;
  }
  &:hover {
    background-color: $grey-lightest;
  }

  &[aria-disabled] {
    color: $grey;
    cursor: not-allowed;

    &:hover {
      background-color: $white;
    }
  }

  &__red {
    color: $red;
    padding: 8px 16px;
    font-size: 14px;

    &:hover {
      background-color: $grey-lightest;
    }
  }
}