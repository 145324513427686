@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.call-mgmt-tab-div {
  background-color: $white;
  flex-basis: 80%;
  height: 100%;
  margin-right: 24px;
  padding-top: 20px;
  padding-right: 5px;
}

.call-mgmt-blue-sub-heading {
  font-size: 25px;
  color: $blue;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.call-mgmt-accordion-spacer-div {
  padding-bottom: 30px;
}

.call-mgmt-ring-to-num {
  //width: 250px;
  display: inline-flex;
}

.call-mgmt-phone-num {
  width: 250px;
  display: inline-flex;
}

.ucl-ring-to-phone-input-div {
  width: 250px;
}

.call-mgmt-separator-line {
  width: 100%;
  color: $grey;
  border-top: solid;
  border-left: solid;
  border-width: thin;
  margin-left: 5px;
}

.call-mgmt-tracking-num-div {
  width: 600px;
  height: 50px;
  display: inline-flex;
  padding-bottom: 20px;
}

.display-provision-date-div {
  padding-top: 5px;
  font-size: small;
  font-style: italic;
}

.call-mgmt-numbers-inline-error-message {
  padding-top: 5px;
  padding-bottom: 15px;
}

.tracking-num-add-remove {
  width: 400px;
  padding-top: 3px;
}

.tracking-num-add-button {
  margin-left: 5px;
  width: 250px;
}

.tracking-num-remove-button {
  color: $red;
  opacity: 0.4;
  margin-top: 4px;
  padding-bottom: 8px;
  width: 50px;
}

.tracking-num-remove-button:hover {
  color: $red;
  opacity: 0.9;
}

.tracking-num-remove-button-disabled {
  color: $red;
  opacity: 0.4;
  padding-bottom: 8px;
  width: 50px;
  pointer-events: none;
}

.trash-can-area {
  display: inline-flex;
}

.trash-label-text {
  font-weight: bold;
  color: $red;
  padding-left: 5px;
  padding-top: 8px;
}

.add-tracking-number-div {
}

.no-tracking-number-warning-div {
  width: 800px;
  padding-left: 5px;
  padding-bottom: 15px;
  display: inline-flex;
}

.no-tracking-number-warning-text {
  width: 100%;
  padding-left: 10px;
  color: $blue;
}

.info-circle-outline {
  color: $blue;
}

.new-car-listing-box {
  background-color: #F5F5F5;
  border: 1px solid $grey-dark;
  border-bottom-color: #bdbdbd;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 30px;
  justify-content: center;
  width: fit-content;
}
