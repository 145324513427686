@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.email-deletion-tool {

  &__layout-wrapper {
    background-color: white;
    border-color: $grey-light;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    flex-direction: row;
    height: 350px;
    margin-top: 20px;
    margin-left: 32%;
    position: absolute;
    width: 600px;
  }

  &__content-div {
    display: block;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 900px;
  }

  &__delete-email-button {
    margin-left: 20px;
    margin-top: 40px;
  }

  &__email-label-div{
    padding-left: 20px;
    padding-top: 40px;
  }

  &__email-input-text {
    width: 100%;
    padding-right: 20px;
  }

  &__info-text-div {
    background-color: $blue-tint-dark;
    border: $blue-light;
    border-style: solid;
    border-radius: 10px;
    display: inline-flex;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
  }

  &__stencils-info-circle {
    margin-right: 20px;
    padding-bottom: 20px;
  }
}

