@import "../../../../../node_modules/@carfax-stencils/styles/src/colors";
@import "../../../../../node_modules/@carfax-stencils/styles/src/typography";


.preferences-search-container-selected {
  background-color: #F3F8FD;
  border-left: 4px;
  border-top: 2px;
  border-top-color: $grey-lightest;
  border-top-style: solid;
  border-left-color: $blue;
  border-left-style: solid;
  box-shadow: 3px 4px 9px $grey;
  margin-top: 20px;
  padding-top: 15px;
  padding-left: 18px;
  padding-bottom: 15px;
  width: 100%;
}

.preferences-search-results-company-name {
  font-weight: bolder;
  font-size: 12px;
  padding-bottom: 11px;
}

.preferences-search-results-street-address {
  font-color: $black;
  font-size: 12px;
  line-height: 3px;
  padding-bottom: 1px;
}

.preferences-search-results-city-state-zip {
  font-color: $black;
  font-size: 12px;
  padding-bottom: 7px;}

.preferences-search-label-comp-code {
  color: $grey-darkest;
  font-size: 12px;
  line-height: 8px;
  padding-top: 7px;
  width: 75px;
}

.preferences-search-value-comp-code {
  font-color: $black;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 97px;
}

.preferences-search-label-account-status {
  color: $grey-darkest;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 95px;
}

.preferences-search-value-account-status {
  font-color: $black;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 80px;
}
