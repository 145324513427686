@import "~@carfax-stencils/styles/src/colors.scss";

.reputation-partner-modal {

  height: 70%;

  &__header {
    font-size: large;
    font-weight: bold;
  }

  &__heading {
    font-weight: bold;
    font-size: 17px;
    padding-bottom: 15px;
  }

  &__subheading {
    font-size: 15px;
    padding-bottom: 15px;
  }

  &__section {
    margin-top: -15px;
  }

  &__label {
    font-size: 15px;
    width: 110px;
  }

  .seperator-line {
    margin-bottom: 20px;
  }

  &__paginator-div-top {
    padding-bottom: 20px;
    display: inline-flex;
    float: right;
  }

  &__paginator-div-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
    display: inline-flex;
    float: right;
  }

  &__table-pager {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__footer-row-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__spinner{
    padding-bottom: 50px;
  }
}

.reputation-partner-history-table {

  &__heading {
    background-color: $grey-lightest;
    border-top: $grey;
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom: $grey;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    font-weight: normal;

    &__TH {
      padding-top: 12px;
      padding-bottom: 12px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 15px;
      font-weight: normal;
    }
  }

  &__column {
    border-top: $grey;
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom: $grey;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 18px 20px 18px 15px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 15px;
    word-break: break-word;
  }
}



