@import "../../../../../node_modules/@carfax-stencils/styles/src/colors";

.user-email-history-modal {

  height: 80%;

  &__header {
    font-size: large;
    font-weight: bold;
  }

  &__heading {
    font-weight: bold;
    font-size: 17px;
    padding-bottom: 15px;
  }

  &__subheading {
    font-size: 15px;
    padding-bottom: 15px;
  }

  &__section {
    margin-top: -15px;
  }

  &__label {
    font-size: 15px;
    width: 110px;
  }

  .user-email-history-info-div {
    display: inline-flex;
    margin-bottom: 30px;
  }

  .user-email-history-last-refresh-div {
    margin-bottom: 30px;
    display: inline-flex;
  }

  .user-email-history-last-refresh-date-text-div {
    margin-right: 10px;
  }

  .user-email-history-footer-refresh-button-div {
    padding-left: 25px;
  }

  .user-email-history-search-spinner-text-div {
    text-align: center;
    font-size: 20px;
  }

  .user-email-history-search-spinner-email-history-text-div {
    padding-bottom: 10px;
  }

  .user-email-history-spinner-div {
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .info-circle-outline {
    color: $black;
    margin-right: 10px;
  }

  .user-email-history-no-history-text-div {
    text-align: center;
    font-size: 20px;
    padding-top: 200px;
  }

  .user-email-history-event-div {
    display: inline-flex;
  }

  .user-email-history-event-alert-icon-visible {
    margin-top: 3px;
    color: $red;
  }

  .user-email-history-event-alert-icon-hidden {
    display: none;
  }
}