@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";


.acct-mgmt-page-with-data {
  padding-right: 20px;
  margin-bottom: 5px;
}

.acct-mgmt-layout-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;
  position: absolute;
  width: 100%;
}

.acct-mgmt-layout-search-wrapper-div {
  overflow-y: scroll;
  height: 100%;
  width: 440px;
  min-width: 440px;
}

.acct-mgmt-layout-search-input {
  padding-left: 20px;
  padding-right: 20px;
}

.acct-mgmt-notification-div {
  margin: 0 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}

.acct-mgmt-search-heading {
  font-weight: bold;
  font-size: 13px;
  margin-left: 21px;
  padding-bottom: 5px;
}

.acct-mgmt-user-details-table-div {
  padding-top: 30px;
}