@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";
@import '@carfax-stencils/styles/src/vars.scss';


.profile-mgmt-page-with-data {
  padding-right: 20px;
  margin-bottom: 5px;
}

.profile-mgmt-layout-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;
  position: absolute;
  height: 3200px;
  width: 100%;
}

.profile-mgmt-layout-search-wrapper-div {
  overflow-y: scroll;
  height: 100%;
  width: 440px;
  min-width: 440px;
}

.profile-mgmt-layout-search-input {
  padding-left: 20px;
  padding-right: 20px;
}

.profile-mgmt-notification-div {
  margin: 0 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}

.profile-mgmt-search-heading {
  font-weight: bold;
  font-size: 13px;
  margin-left: 21px;
  padding-bottom: 5px;
}

.profile-mgmt-tablist {
  flex-wrap: wrap;
}

.profile-mgmt-footer {
  background-color: $white;
  border: 1px solid #e0e0e0;
  border-bottom-color: #bdbdbd;
  border-radius: 5px;
  bottom: 0;
  display: flex;
  flex-basis: 80%;
  margin-right: 24px;
  padding-top: 20px;
  position: sticky;
  justify-content: space-between;
  z-index: $zIndex-grass;
}

.profile-mgmt-footer-audit-button-div {
  text-align: right;
  padding-bottom: 20px;
  padding-right: 25px;
}

.profile-mgmt-footer-refresh-button-div {
  text-align: right;
  padding-bottom: 20px;
  padding-right: 25px;

  &__hidden {
    display: none;
  }
}

.profile-mgmt-footer-blue-buttons-div {
  text-align: left;
  padding-left: 30px;
  padding-bottom: 20px;
}

.profile-mgmt-footer-white-buttons-div {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
}

.profile-mgmt-move-pref-div {
  display: flex;
  flex-basis: 80%;
  margin-right: 24px;
  padding-top: 20px;
  justify-content: space-between;
}

.profile-mgmt-move-pref-header-fit-width {
  width: fit-content !important;
}

.profile-mgmt-content-wrapper {
  flex-basis: 100%;
}

.profile-mgmt-move-pref-icon {
  margin: 5px 5px 0px 0px;
}