@import "~@carfax-stencils/styles/src/colors.scss";


.location-selection-cell {
  &:first-of-type {
    padding-left: 18px;
    background-color: $white;
    font-weight: bold;
  }
  &:nth-of-type(2) {
    padding-left: 18px;
    background-color: $white;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 28px;
  padding-left: 38px;
  background-color: $grey-lightest;
  border-bottom: 1px solid $grey;
  font-size: 14px;
}
.selection-header-title-div {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}
.selection-header-search-div {
  padding-bottom: 10px;
  font-weight: 500;
  width: 550px;
}
.selection-input{
  padding-top: 1px;
}
.selection-header-location{
  width: 420px;
}
.selection-header-ucl{
  padding-right: 4px;
}
.selection-header-c4l{
}

.modal_body_container {
  padding: 0px;
}
.location-selection-modal {
  max-width: 800px;
}
.selection-col-location {
  width: 300px;
}
.selection-col-ucl {
  padding-right: 28px;
}
.selection-col-c4l {
  padding-right: 40px;
}
.selection-col-text {
  font-size: 14px;
}
