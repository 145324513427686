@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.home-page-wrapper {
  font-family: Roboto;
  width: 100%;
  height: 650px;
  background-color: $grey-lightest;
  position: relative;

  .home-title {
    font-size: 40px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
  }

  .home-description {
    height: 48px;
    color: $black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    padding: 0% 20%
  }

  .home-card-container {
    display: flex;
    justify-content: center;
    align-content: stretch;
    flex-direction: row;
    margin-top: 30px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .home-card {
    width: 292px;
    height: 205px;
    margin: 0 12px;
    flex: initial;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: lightgrey;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: lightgrey;

    &__image-container {
      width: 100%;
      min-height: 160px;
      border-top-style: solid;
      border-top-width: 1px;
      border-top-color: lightgrey;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: lightgrey;
      border-radius: 3px;
      background-color: #F3F8FD;

      img {
        display: block;
        margin: auto;
        padding-top: 30px;
      }
    }

    &__title {
      @extend %text-body-large;
      color: $grey-darkest;
      font-weight: bold;
      text-align: center;
    }

    &__link {
      @extend %text-body-large;
      font-weight: bold;
      text-align: center;
      padding: 15px;
      background-color: white;
      border-radius: 0 0 9px 9px;
      border-width: 2px;
      border-bottom-style: solid;
      border-bottom-width: 2px;
      border-bottom-color: lightgrey;
      a {
        color: $blue;
      }
    }
  }

}
