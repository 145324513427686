@import "~@carfax-stencils/styles/src/colors.scss";

.create-legacy-user-modal {

  height: 80%;

  &__header {
    font-size: large;
    font-weight: bold;
  }

  &__body {
    height: 100%;
    flex-grow: 1;
    overflow-y: auto;
    padding: 0px;
  }
}

.create-legacy-user-input-div {
  padding-top: 40px;
  padding-bottom: 70px;
  padding-left: 40px;
  width: 550px;
  height: 250px;
}