// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700);

// Reset/Stencils/Stencils Changes
@import "./_stencils.scss";
@import "./index.scss";

.bold {
  font-weight: bold;
}

.bottom-padding {
  padding-bottom: 30px;
}

.breadcrumb-trail {
  background-color: $grey-lightest;
  width: 100%;
  padding-top: 10px;
  padding-left: 24px;
  padding-bottom: 5px;
  font-size: 12px;
  text-align: left;
}

.dollar-sign-icon {
  color: gray;
  padding-right: -10px;
}

.feature-notification-layout {
  font-weight: bold;
  padding-bottom: 10px;
  padding-right: 25px;
}

.horizontal-line {
  background-color: $grey-light;
  height: 1px;
  margin: 10px 10px 10px 15px;
}

.input-label {
  color: $black;
  font-weight: bold;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.input-label-star {
  color: $red;
  font-weight: bold;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.inline-flex {
  display: inline-flex;
}

.left-padding {
  padding-left: 5px;
}

.main-heading {
  color: $black;
  font-size: 22px;
  font-weight: bold;
  height: 40px;
  letter-spacing: 0;
  line-height: 40px;
  padding-bottom: 60px;
  padding-left: 5px;
  width: 100%;
}

.modal-header-title {
  padding-top: 15px;
  font-weight: 500;
}

.nowrap {
  white-space: nowrap;
}

.padlock-icon {
  padding-top: 2px;
  color: gray;
}

.primary_label {
  font-size: 14px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 30px;
  padding-right: 10px;
}

.primary_label_text {
  color: $grey-darkest;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  letter-spacing: 0px;
  line-height: 30px;

  &__bold {
    font-weight: bold;
  }
}

.primary_text {
  color: $grey-darkest;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  letter-spacing: 0px;

  &__bold {
    font-weight: bold;
  }
}

.required-field::after {
  content: " *";
  color: red;
}

.right-padding-25 {
  padding-right: 25px;
}

.secondary_text {
  color: $grey-darkest;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  letter-spacing: 0px;
  line-height: 20px;

  &__bold {
    font-weight: bold;
  }
}

.secure-qa-group-override-message {
  background-color: orangered;
}

.separator-line {
  width: 100%;
  color: $grey;
  border-top: solid;
  border-left: solid;
  border-width: thin;
  margin-left: 5px;
  margin-right: 5px;
  padding-right: 22px;
}

.sub-heading {
  color: $black;
  font-size: 19px;
  font-weight: bold;
  height: 40px;
  letter-spacing: 0;
  line-height: 40px;
  padding-bottom: 35px;
  padding-left: 5px;
  padding-top: 15px;
  width: 100%;
}

.tertiary_text {
  color: $grey-darkest;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 13px;
  letter-spacing: 0px;
}

.toggle-label {
  font-size: 14px;
  padding-right: 15px;
}

.top-padding {
  padding-top: 20px;
}