@import "../../../node_modules/@carfax-stencils/styles/src/colors";
@import "../../../node_modules/@carfax-stencils/styles/src/typography";

.enhanced-email-input-row {
  width: 100%;
}

.enhanced-email-input-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.enhanced-email-remove-button {
  color: $red;
  opacity: 0.4;
  padding-top: 8px;
}

.enhanced-email-input-text {
  width: 655px;
  margin-right: 50px;
}

.enhanced-email-remove-button:hover {
  color: $red;
  opacity: 0.9;
}

.all-car-listings-enhanced-radio-input {
  width: 70px;
  color: white;
}

.used-car-listings-enhanced-radio-input {
  width: 70px;
  color: white;
}

.new-car-listings-enhanced-radio-input {
  width: 70px;
  color: white;
  margin-left: 10px;
}