@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";


.aggregate-to-input-width {
  width: 180px;
}

.backfill-inline-error-div {
  padding-bottom: 5px;
}

.cpo-listing-inline-error-div {
  padding-bottom: 20px;
}

.crm-information-input-width {
  width: 500px;
}

.dealer-penalty-input-width {
  width: 200px;
}

.disable-ucl-listings-settings-input-width {
  width: 300px;
}

.inventory-information-input-width {
  width: 700px;
}

.inventory-input-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.inventory-input-delete-button {
  padding-left: 100px;
}

.inventory-key-remove-button {
  color: $red;
  opacity: 0.4;
  padding-top: 8px;
}

.inventory-key-remove-button:hover {
  color: $red;
  opacity: 0.9;
}

.new-used-car-listings-container {
  height: 380px;
  width: 430px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  background-color: #F5F5F5;
  align-content: center;
}

.secondary-name-header-input-row {
  display: inline-flex;
  width: 100%;
}

.secondary-name-input-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.secondary-name-input-width {
  width: 400px;
}

.secondary-name-label {
  padding-right: 120px;
}

.secondary-name-oem-input {
  width: 210px;
}

.secondary-name-oem-label {
  padding-right: 187px;
}

.secondary-name-remove-button {
  color: $red;
  opacity: 0.4;
  padding-top: 8px;
}

.secondary-name-remove-button:hover {
  color: $red;
  opacity: 0.9;
}

.syndicate-inline-error-div {
  padding-bottom: 20px;
}

.used-car-listings-tab-div {
  background-color: $white;
  flex-basis: 80%;
  height: 100%;
  margin-bottom: 10px;
  margin-right: 24px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 30px;
}

.used-car-listings-tab-layout {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;
  height: 100%;
}

.website-url-input-width {
  width: 380px;
  padding-right: 10px;
}

.website-url-label {
  padding-right: 188px;
}
