@import "../../../../../node_modules/@carfax-stencils/styles/src/colors";

.user-audit-history-modal {

  height: 80%;

  &__header {
    font-size: large;
    font-weight: bold;
  }

  &__heading {
    font-weight: bold;
    font-size: 17px;
    padding-bottom: 15px;
  }

  &__subheading {
    font-size: 15px;
    padding-bottom: 15px;
  }

  &__section {
    margin-top: -15px;
  }

  &__label {
    font-size: 15px;
    width: 110px;
  }

  .filters-div-row {
    display: inline-flex;
    padding-bottom: 25px;
  }

  .filter-input-type-div {
    padding-right: 10px;
  }

  .seperator-line {
    margin-bottom: 20px;
  }
}

.user-audit-history-paginator {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-audit-history-paginator-div-top {
  padding-top: 80px;
  padding-bottom: 20px;
  display: inline-flex;
  float: right;
}

.user-audit-history-paginator-div-bottom {
  padding-top: 20px;
  padding-bottom: 20px;
  display: inline-flex;
  float: right;
}