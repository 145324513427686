@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.dev-cfo-remote-login-modal {
  background-color: $white;
  flex-basis: 80%;
  height: 350px;
  margin-bottom: 10px;
  margin-right: 24px;
  padding-right: 5px;
  padding-top: 10px;

  &__header {
    font-size: large;
    font-weight: bold;
  }
}

.dev-cfo-remote-login-label {
  padding-top: 10px;
  padding-bottom: 30px;
  height: 24px;
  font-size: 16px;
  font-weight: bold;
}

.dev-cfo-remote-login-button-div {
  padding-top: 20px;
}

.dev-cfo-remote-login-resolved-url {
  padding-top: 10px;
}