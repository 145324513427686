@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.stencils {
  font-family: "Roboto";
}
.table-component {
  table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;

    thead {
      box-shadow: inset 0 1px 0 0 #e0e0e0, inset 0 0 0 0 #e0e0e0,
        inset 0 -1px 0 0 #eeeeee, 0 0 0 0 #e0e0e0;
    }

    th {
      background-color: #ffffff;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      height: 50px;
      text-align: left;
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none; /* Likely future */
      color: $grey-dark;
      font-size: 12px;
      &:first-child {
        padding-left: 24px;
      }

      &.active {
        color: $grey-darkest;
      }

      &.sortable {
        cursor: pointer;
      }
    }

    tr:nth-child(odd) {
      background-color: #fafafa;
      box-shadow: inset 0 1px 0 0 #e0e0e0, inset 0 0 0 0 #e0e0e0,
        inset 0 -1px 0 0 #eeeeee, 0 0 0 0 #e0e0e0;
    }

    td {
      box-sizing: border-box;
      padding: 24px 0px;

      font-family: Roboto;
      font-size: 14px;
      line-height: 24px;
      &:first-child {
        padding-left: 24px;
      }
    }
  }

  .tablePagination {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
  }
}
