@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.dealer-details-tab-div {
  background-color: $white;
  flex-basis: 80%;
  height: 100%;
  margin-bottom: 10px;
  margin-right: 24px;
  padding-right: 5px;
  padding-top: 30px;
}

.dealer-details-tab-layout {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;
  padding-bottom: 110px;
  height: 100%;
}

.dealership-info-details-div {
  padding-left: 15px;
  display: inline;
}

.dealership-info-details-layout {
  padding-top: 20px;
  padding-bottom: 30px;
  display: inline-flex;
}

.dealership-info-field-div {
  padding-bottom: 5px;
}

.dealership-info-location-icon {
  color: $grey;
}

.dealership-info-ucl-listings-hyperlink {
  padding-top: 2px;
}

.dealership-info-ucl-listings-hyperlink:hover {
  padding-top: 2px;
  text-decoration: underline;
}

.document-fee-input-text {
  padding-bottom: 30px;
  width: 195px;
}

.dealer-logo-div {
  background-color: #ebebeb;
  border: 1px solid $grey-dark;
  border-bottom-color: #bdbdbd;
  border-radius: 10px;
  margin-left: 5px;
  margin-top: 15px;
  margin-bottom: 20px;
  height: 100px;
  width: 120px;
  padding-top: 5px;
}

.dealer-photo-div {
  background-color: #ebebeb;
  border: 1px solid $grey-dark;
  border-bottom-color: #bdbdbd;
  border-radius: 10px;
  margin-left: 5px;
  margin-top: 15px;
  margin-bottom: 5px;
  height: 100px;
  width: 120px;
  padding-top: 5px;
}

.value-badge-consent-toggle {
  padding-top: 20px;
}

.value-badge-consent-subtext {
  padding-bottom: 35px;
  padding-top: 12px;
  font-style: italic;
}

.separate-carlistings-toggle {
  padding-top: 20px;
  display: inline-flex;
}

.separate-carlistings-separator-line {
  margin: 30px 0 30px 0;
}

.customer-disclaimer-text-area {
  padding-bottom: 30px;
}

.hours {
  margin: 24px 0 8px 0;
  color: $grey-dark;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.hours-description {
  padding-bottom: 30px;
}

.hours-subheadings-container {
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  flex-direction: row;

  .open-close {
    margin-bottom: 15px;
    margin-left: 110px;
  }
  .appointment {
    margin-bottom: 8px;
    margin-left: 20px;
  }
  .toggle {
    margin-left: 12px;
  }
  .open-at {
    margin-left: 110px;
    margin-left: 12px;
  }
  .close-at {
    margin-left: 110px;
  }
}

.hours-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 16px;
  padding-left: 5px;

  &__day {
    width: 100px;
    position: absolute;
  }

  &__day-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    width: 500px;
    padding-right: 24px;
  }

  &__open-close {
    margin-left: 140px;
    width: 50px;
  }

  &__appt {
    margin-left: 80px;
    width: 70px;
  }

  &__hours-open {
    margin-left: 50px;
    width: 150px;
  }

  &__hours-close {
    margin-left: 30px;
    width: 150px;
  }

  &__error {
    color: #f44336;
    width: 100%;
    height: 10px;
    font-size: 14px;
    line-height: 14px;
    font-family: Roboto;
    margin-bottom: 24px;
    margin-left: 372px;
  }
}

.new-and-used-entry-box {
  background-color: #ebebeb;
  border: 1px solid $grey-dark;
  border-bottom-color: #bdbdbd;
  border-radius: 10px;
  margin-top: 15px;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 25px;
  justify-content: center;
}

.nested-input {
  padding-right: 15px;
}