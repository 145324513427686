

.selection-header-title {
  padding-top: 15px;
  font-weight: 500;
}

.add-location-modal {
  max-width: 800px;
}

.add-location-modal-body {
  padding: 30px 50px;
}

.add-location-selector {
}

.add-location-checkbox-c4l {
  padding: 30px 10px 25px;
}

.add-location-checkbox-ucl {
  padding: 0px 10px 30px;
}
