@import "~@carfax-stencils/styles/src/colors.scss";

.edit-legacy-user-modal {

  height: 80%;

  &__header {
    font-size: large;
    font-weight: bold;
  }

  &__body {
    height: 100%;
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;
  }
}

.edit-legacy-user-input-div {
  padding: 10px 0px 100px 10px;
  width: 200px;
  height: 100px;
}

.edit-legacy-user-name-div {
  padding: 10px 0px 100px 10px;
  width: 300px;
  height: 100px;
}

.edit-legacy-user-password-div {
  padding: 10px 0px 100px 10px;
  width: 200px;
  height: 100px;
}

.edit-legacy-user-modal-password-input-div {
  display: inline-flex;
}

.edit-legacy-user-modal-password-input {
  width: 235px;
  padding-right: 30px;
}

.edit-legacy-user-modal-password-generate-button {
  width: 200px;
  margin-top: 2px;
}

.edit-legacy-user-status-div {
  padding: 10px 0px 200px 15px;
  width: 200px;
  height: 100px;
}