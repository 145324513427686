@import "../../../node_modules/@carfax-stencils/styles/src/colors";
@import "../../../node_modules/@carfax-stencils/styles/src/typography";

.email-input-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.email-input {
  width: 700px;
}

.email-remove-button {
  color: $red;
  opacity: 0.4;
  padding-top: 8px;
}

.email-remove-button:hover {
  color: $red;
  opacity: 0.9;
}