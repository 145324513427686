@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";
@import "~@carfax-stencils/styles/src/declarative.scss";


.partner-maintenance-page {
  background-color:  $grey-lightest;
}

.partner-maintenance-layout {
  display: flex;
  flex-direction: row;
}

.partner-maintenance-add-partner {
  form {
    width: 450px;
    padding: 20px 16px;
    display: flex;
    font-size: 14px;
    flex-shrink: 0;
    text-align: center;
    float: left;

    input {
      display: flex;
      justify-content: flex-start;
      width: 230px;
    }

    button {
      width: 220px;
      font-size: 12px;
      color: $white;
      background-color: #3777bc;
    }
  }
}

.partner-maintenance-table {
  margin: 0 24px;

  .table-pager {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .table-component {
    table {
      width: 100%;
      border-spacing: 0;
      td {
        word-wrap: break-word;
        margin-right: 5px;
        padding: 5px 5px 5px;
      }
    }

    @media print {
      @page {
        size: A4;
        margin: 2cm;
      }
      table {
        margin: 0;
        th {
          height: 24px;
          color: black !important;
          padding: 0 10px 0 0;

          svg {
            display: none;
          }
        }

        td {
          padding: 0 10px 0 0;
          font-size: 8px;
          height: 30px;
          line-height: 12px;
          color: black !important;
          border-top: 1px solid gray;
        }

        tr {
          background-color: white !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
