@import "../../../node_modules/@carfax-stencils/styles/src/colors";
@import "../../../node_modules/@carfax-stencils/styles/src/typography";

.search-container-white {
  background-color: white;
  border-left: 4px;
  border-top: 2px;
  border-top-color: $grey-lightest;
  border-top-style: solid;
  box-shadow: 3px 4px 9px $grey;
  div:hover{background-color: #F3F8FD};
  margin-top: 20px;
  padding-top: 15px;
  padding-left: 18px;
  padding-bottom: 15px;
  width: 100%;
}

.search-container-selected {
  background-color: #F3F8FD;
  border-left: 4px;
  border-top: 2px;
  border-top-color: $grey-lightest;
  border-top-style: solid;
  border-left-color: $blue;
  border-left-style: solid;
  box-shadow: 3px 4px 9px $grey;
  margin-top: 20px;
  padding-top: 15px;
  padding-left: 18px;
  padding-bottom: 15px;
  width: 100%;
}

.search-results-row {
  padding-top: 2px;
}

.search-results-company-name {
  font-weight: bolder;
  font-size: 12px;
  padding-bottom: 11px;
}

.search-results-industry-label {
  color: $grey-darkest;
  font-size: 12px;
  line-height: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 7px;
}

.search-results-industry-value {
  font-color: $black;
  font-size: 12px;
  line-height: 18px;
}

.search-results-salesforceId-label {
  color: $grey-darkest;
  font-size: 12px;
  line-height: 8px;
  padding-top: 8px;
  padding-bottom: 18px;
  padding-right: 7px;
}

.search-results-salesforceId-value {
  font-color: $black;
  font-size: 12px;
}

.search-results-street-address {
  font-color: $black;
  font-size: 12px;
  line-height: 3px;
  padding-bottom: 1px;
}

.search-results-city-state-zip {
  font-color: $black;
  font-size: 12px;
  padding-bottom: 7px;
}

.search-label-comp-code {
  color: $grey-darkest;
  font-size: 12px;
  line-height: 8px;
  padding-top: 7px;
  width: 75px;
}

.search-value-comp-code {
  font-color: $black;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 97px;
}

.search-label-account-status {
  color: $grey-darkest;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 95px;
}

.search-value-account-status {
  font-color: $black;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 80px;
}

.search-label-ucl-status {
  color: $grey-darkest;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 72px;
}

.search-value-ucl-status {
  font-color: $black;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 100px;
}

.search-label-phone {
  color: $grey-darkest;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 45px;
}

.search-value-phone {
  font-color: $black;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 120px;
}

.search-label-til-dealer {
  color: $grey-darkest;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 125px;
}

.search-value-til-dealer {
  font-color: $black;
  font-size: 12px;
  width: 47px;
}

.search-label-vrss {
  color: $grey-darkest;
  font-size: 12px;
  line-height: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 40px;
}

.search-value-vrss {
  font-color: $black;
  font-size: 12px;
  width: 49px;
}

.search-results-suspended-label {
  color: $red;
  font-weight: bold;
  padding-left: 7px;
  padding-right: 10px;
}