@import "~@carfax-stencils/styles/src/colors.scss";

.unsaved-changes-modal {

  &__header {
    font-size: large;
    font-weight: bold;
  }

  &__subheading {
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 10px;
  }

  &__message {
    margin-top: 5px;
    padding-bottom: 40px;
  }

  &__footer {
    margin-top: auto;
  }

  .alert-icon {
    color: $red;
    padding-right: 10px;
  }
}
