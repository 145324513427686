@import "../../../../../node_modules/@carfax-stencils/styles/src/colors";
@import "../../../../../node_modules/@carfax-stencils/styles/src/typography";


%image-drop-zone-base {
  width: 100%;
  height: 100%;
}

.image-drop-zone {
  &-normal {
    border: 1px solid $blue-light;
    color: $blue;
    padding-right: 20px;
    @extend %image-drop-zone-base;
  }
  &-active {
    border: 2px solid green;
    @extend %image-drop-zone-base;
  }
}

.dropzone-root-div {
  padding: 3px;
  size: inherit;
  height: 100%;
  width: 100%;
  position: absolute;
}

.dropzone-activity-overlay {
  padding-left: 35px;
  opacity: 0.8;
  > .spinner {
    transform: translate(0px, 30%);
    margin: 0px;
    padding: 0px;
    > .spinner-container {
      width: 75px;
      height: 75px;
      margin: auto;
      padding: 0px;
      > .spinner-svg {
        margin: 0px;
        padding: 0px;
        width: auto;
        > .spinner-gears {
          left: 0px;
          top: 0px;
        }
      }
    }
  }
}

.add-photo-component {
  background-color: $blue-light;
}

.add-photo-overlay-component {
  size: inherit;
  margin-top: 5px;
  margin-right: 37px;
  float: right;
  color: $blue;
}

.add-photo-overlay-div {
  width: 112px;
  height: 85px;
  max-height: fit-content;
}

.upload-photo-label {
  font-size: 12px;
  width: 100px;
  height: 42px;
  color: #1976d2;
  display: inline-block;
  margin-left: 20px;
  margin-top: 5px;
}

.preview-image-div {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.preview-image {
  height: 100%;
  width: 100%;
}

.image-remove {
  color: $red;
  opacity: 0.4;
}

.image-remove:hover {
  color: $red;
  opacity: 0.9;
}

.image-remove-icon-div {
  position: absolute;
  margin-left: -4px;
  margin-top: -8px;
}

.image {
  height: 100%;
  width: 100%;
}

.photos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.logo-profile-image-container {
  position: relative;
  max-width: 70%;
  width: 120px;
  height: 91px;
  margin-bottom: 2px;
  margin-right: 2px;
}

.photo-profile-image-container {
  position: relative;
  max-width: 70%;
  width: 120px;
  height: 91px;
  margin-bottom: 2px;
  margin-right: 2px;
}
