@import "../../../node_modules/@carfax-stencils/styles/src/colors";
@import "../../../node_modules/@carfax-stencils/styles/src/typography";

.enhanced-email-header-input-row {
  display: inline-flex;
  padding: 0px 0px 15px 650px;
  width: 100%;
}

.all-car-listings-label {
  width: 60px;
  font-size: 15px;
  overflow: auto;
  text-align: center;
  margin-left: 40px;
}

.used-car-listings-label {
  width: 80px;
  font-size: 15px;
  overflow: auto;
  text-align: center;
  margin-left: 15px;
}

.new-car-listings-label {
  width: 80px;
  font-size: 15px;
  overflow: auto;
  text-align: center;
  margin-left: 8px;
}

.delete-label {
  font-size: 15px;
  overflow: auto;
  text-align: center;
  margin-left: 25px;
  padding-top: 10px;
}
