@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.run-reports {

  &__layout-wrapper {
    background-color: white;
    border-color: $grey;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    flex-direction: row;
    height: 500px;
    margin-top: 20px;
    margin-left: 32%;
    position: absolute;
    width: 600px;
  }

  &__content-div {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 500px;
  }

  &__label-div{
    padding-left: 20px;
    padding-top: 20px;
  }

  &__dropdown {
    padding-right: 20px;
  }

  &__submit-button {
    margin-left: 20px;
    margin-top: 40px;
  }

  &__submit-button-uas-new {
    margin-left: 20px;
    margin-top: 25px;
  }
}

.am-metrics {
  &__text-input-div{
    padding-left: 20px;
    padding-top: 40px;
    padding-right: 40px;
  }
}

.uas-user-new {
  &__text-input-div{
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 40px;
  }
}