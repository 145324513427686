@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";


.dealer-preferences-layout {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.dealer-preferences-layout-tabs {
  border-top: hidden;
}

.dealer-preferences-layout-search {
  background-color: $white;
  border: 1px solid #e0e0e0;
  border-bottom-color: #bdbdbd;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 24px;
  margin-left: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 393px;
  min-width: 393px;
}

.dealer-preferences-layout-body {
  flex-basis: 80%;
}

.dealer-preferences-layout-message {
  padding-bottom: 10px;
  padding-right: 25px;
  font-weight: bold;
}

.dealer-preferences-layout-table {
  background-color: $white;
  border: 1px solid #e0e0e0;
  border-bottom-color: #bdbdbd;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 24px;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  flex-basis: 80%;
}

.dealer-preferences-layout-table-heading {
  height: 40px;
  width: 100%;
  color: black;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  padding-top: 25px;
  padding-left: 23px;
  padding-bottom: 60px;
}

.dealer-preferences-layout-table-row-div {
  button {
    width: 220px;
    font-size: 12px;
  }
}

.dealer-preferences-search-panel {
  background-color: white;
  width: 25%;
}

.dealer-preferences-layout-search-input {
  padding-left: 20px;
  padding-right: 20px;
}

.dealer-preferences-notification-div {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 15px;
}

.dealer-preferences-results-panel {
  background-color: white;
  width: 75%;
}

.dealer-preferences-button {
  height: 48px;
  width: 250px;
  font-size: 12px;
  margin-top: 25px;
  padding-bottom: 30px;
  margin-left : 10px;
  white-space: nowrap;
}


// CSS for search stuff, don't know if need
.search-container {
  background-color: #f3f8fd;
  border-left: 3px;
  border-top: 2px;
  border-top-color: $grey-lightest;
  border-top-style: solid;
  border-left-color: $blue;
  border-left-style: solid;
  box-shadow: 1px 3px 9px $grey-light;
  margin-top: 20px;
  padding-top: 15px;
  padding-left: 18px;
  padding-bottom: 15px;
}

.search-icon {
  color: $blue;
  margin-right: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}

.search-label {
  font-size: 10px;
  padding-right: 7px;
}

.search-results-row {
  padding-top: 2px;
}

.search-value {
  color: $black;
}

.search-results-comp-code {
  font-size: 11px;
}

.search-results-company-name {
  font-weight: bold;
  font-size: 12px;
  padding-bottom: 10px;
}

.search-results-street-address {
  font-size: 11px;
  line-height: 3px;
  padding-bottom: 5px;
}

.search-results-city-state-zip {
  font-size: 11px;
  padding-bottom: 5px;
}

.search-results-active {
  font-size: 11px;
}

.status-bullet {
  font-size: 11px;
  padding-right: 5px;
  svg {
    fill: currentcolor;
    height: 11px;
    width: 11px;
    transform: translateY(-2px);
  }
}

.status-cell {
  background-color: #edf6fc;
  width: 120px;
  height: 30px;
  padding-left: 10px;
  padding-top: 1%;
  border-radius: 20px;
  display: flex;
}

.status-submitted {
  margin-top: 7px;
  background-color: $green;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.status-in-progress {
  margin-top: 7px;
  background-color: $yellow;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.status-not-started {
  margin-top: 7px;
  background-color: $red;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.status-text {
  padding-left: 10px;
  white-space: nowrap;
}

.delete-trash-icon {
  color: $red;
  height: 24px;
  width: 24px;
  position: relative;
  cursor: pointer;
}
