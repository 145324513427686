@import "~@carfax-stencils/styles/src/colors.scss";

.move-user-modal {
  padding: 25px;

  &__message {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 15px;
  }

  &__oval {
    padding-top: 3px;
    width: 32px;
    height:32px;
    background: $blue;
    color: $white;
    border-radius: 50%;
  }

  &__step-text {
    color: $blue;
    padding-left: 20px;
    padding-top: 3px;
  }

  &__comp-code {
    padding-top: 10px;
  }

  &__ineligible_users_selected_div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.move-user-modal-basic {

  &__body {
    height: 750px;
    padding: 25px;
  }

  &__search {
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__search-icon {
    color: $blue;
    margin-bottom: 10px;
    cursor: pointer;
  }

  &__dealer-status-active {
    margin-top: 6px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: $green;
    padding-right: 10px;
  }

  &__dealer-status-inactive {
    margin-top: 6px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: $grey-dark;
    padding-right: 10px;
  }

  &__user-location-status {
    border-radius: 20px;
    display: flex;
  }

  &__location-status-text {
    padding-left: 10px;
  }

  &__empty-body {
    height: 275px;
    padding: 25px;
  }

  &__empty-header {
    font-size: 24px;
    padding-top: 70px;
    text-align: center;
  }

  &__empty-text {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 10px;
    font-size: 14px;
    text-align: center;
  }

}

.move-user-modal-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0;
}

.move-user-confirmation {
  padding: 20px;

  &__title {
    padding-top: 10px;
    padding-bottom: 15px;
  }

  &__details {
    font-size: 14px;
    padding: 20px;
    border: 1px solid $grey;
    background-color: $grey-lightest
  }

  &__column1 {
    padding-right: 30px;
  }
  &__column2 {

  }
}

.ineligible-users-section {
  &__container {
    padding-bottom: 30px;
  }

  &__msg_div {
    border: $blue-dark;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 15px;
  }

  &__content {
    display: inline-flex;
  }

  &__icon {
    color: $blue-dark;
    margin-top:5px
  }

  &__msg-text {
    padding-left: 15px;
  }
}

.basic-move-user-label-text {
  color: #707070;
}