@import "~@carfax-stencils/styles/src/colors.scss";

.move-profile-preferences {
  &__message {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  &__oval {
    padding-top: 3px;
    width: 32px;
    height:32px;
    background: $blue;
    color: $white;
    border-radius: 50%;
  }

  &__step-text {
    color: $blue;
    padding-left: 20px;
    padding-top: 3px;
  }

  &__top-padding {
    padding-top: 20px;
  }

  &__top-margin {
    margin-top: 20px;
  }

  &__seperator-line {
    width: 100%;
    color: $grey;
  }

  &__table-details {
    font-size: 14px;
    padding: 20px;
    border: 1px solid $grey;
    background-color: $grey-lightest
  }

  &__table-column1 {
    padding-right: 30px;
  }

}

