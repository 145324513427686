@import "~@carfax-stencils/styles/src/colors.scss";

.provision-modal {

  &__header {
    font-size: large;
    font-weight: bold;
  }

  &__heading {
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 15px;
  }

  &__subheading {
    font-size: 15px;
    padding-bottom: 15px;
  }

  &__section {
    margin-top: -15px;
  }

  &__label {
    font-size: 15px;
    width: 110px;
  }

  &__local-search-div {
    padding-top: 10px;
    padding-bottom: 15px;
  }

  &__local-number-input {
    padding-left: 5px;
    padding-right: 2px;
    width: 75px;
    text-align: center;
  }

  &__local-number-input_error {
    border-width: 1px;
    border-style: solid;
    border-color: red;
    border-radius: 5px;
  }

  &__left-parenthesis {
    padding-right: 2px;
    padding-top: 10px;
    font-size: 20px;
  }

  &__right-parenthesis {
    padding-left: 5px;
    padding-right: 10px;
    padding-top: 10px;
    font-size: 20px;
  }

  &__phone-number-div {
    display: inline-flex;
    padding-top: 15px;
    padding-bottom: 20px;
  }

  &__prefix-number-dash {
    padding-left: 10px;
    padding-right: 15px;
    padding-top: 12px;
    font-size: 17px;
  }

  &__confirm-preview-div {
    padding: 20px;
    background-color: $grey-lightest;
    height: 100%;
    width: 100%;
    border-style: solid;
    border-color: $grey;
    border-width: 1px;
    border-radius: 5px;
  }

  .confirm-number-item-row {
    padding-bottom: 5px;
  }

  .confirm-phone-number-text {
    font-weight: bold;
    font-size: 14px;
  }

  .provision-api-spinner-div {
    z-index: 100;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  .twilio-error-notification-div {
    padding-bottom: 15px;
  }

}
