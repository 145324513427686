@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.regenerate-top-rated {

  &__layout-wrapper {
    background-color: white;
    border-color: $grey-light;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    flex-direction: row;
    height: 290px;
    margin-top: 20px;
    margin-left: 32%;
    position: absolute;
    width: 600px;
  }

  &__layout-confirmation-wrapper {
    background-color: white;
    border-color: $grey-light;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    flex-direction: row;
    height: 355px;
    margin-top: 20px;
    margin-left: 32%;
    position: absolute;
    width: 600px;
  }

  &__content-div {
    display: block;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 900px;
  }

  &__input-div {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &__file-uploader-div {
    position: absolute;
    padding-top: 30px;
    padding-left: 15px;
  }

  &__description-text {
    padding-left: 15px;
    padding-right: 40px;
    color: $grey-darkest;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    letter-spacing: 0;
  }

  &__title-text {
    padding-left: 15px;
    padding-right: 40px;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
  }

  &__upload-heading {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    color: $blue;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    letter-spacing: 0;
  }

  &__regenerate-assets-div {
    flex-Direction: row;
    display: flex;
  }

  &__next-button {
    margin-top: 33px;
    margin-right: 15px;
  }

  &__submit-button {
    margin-top: 20px;
    margin-right: 15px;
  }

  &__compCode-info-div {
    background-color: #ebebeb;
    border: 1px solid $grey-dark;
    border-bottom-color: #bdbdbd;
    margin-top: 15px;
    padding-top: 25px;
    padding-left: 20px;
    padding-bottom: 25px;
    display: grid;
    grid-template-columns: auto auto
  }
}