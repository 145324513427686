@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.ratings-and-reviews-tab-div {
  background-color: $white;
  flex-basis: 80%;
  height: 100%;
  margin-bottom: 10px;
  margin-right: 24px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 30px;
}

.ratings-and-reviews-tab-layout {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;
  height: 100%;
}

.ratings-and-reviews-spaces-div {
  margin-top: 500px;
}


