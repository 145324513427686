@import "~@carfax-stencils/styles/src/colors.scss";

.modify-user-header-title-div {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.modify-user-modal-body {
  height: 100%;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0;
}

.modify-user-tabs {
  border-top-style: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
}

.modify-user-tab-item {
  padding-left: 15px;
}

.modify-user-settings-header {
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 30px;
  padding-left: 5px;
  padding-top: 20px;
}

.basic-user-info-role-name {
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.basic-user-info-label {
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.basic-user-info {
  padding: 15px;

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }

  &__first {
    padding-right: 24px;
    width: 50%;
  }

  &__last {
    width: 50%;
  }

  &__email {
    width: 100%;
  }

  &__phone {
    width: 30%;
  }

  &__todo {
    margin-top: 10px;
    padding-left: 75px;
  }
}

.email-preferences-table {
  &__heading {

    &__TH {
      font-size: 16px;
      font-weight: 700;
      border-top-style: none;
      border-bottom-style: none;
      background-color: $white;
    }
  }
  &__row {

  }
  &__column {
    border-bottom-style: none !important;
  }

}

.modify-associated-locations {
  &__body {
    height: 750px;
    padding: 25px;
  }

  &__search {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__text-input {
    width: 355px;
  }

  &__search-icon {
    color: $blue;
    margin-bottom: 10px;
    cursor: pointer;
  }

  &__search-label {
    padding-top: 8px;
    padding-left: 15px;
    font-size: 14px;
  }

  &__search-count {
    padding-left: 5px;
  }

  &__empty-body {
    height: 275px;
    padding: 25px;
  }

  &__empty-header {
    font-size: 24px;
    padding-top: 70px;
    text-align: center;
  }

  &__empty-text {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 10px;
    font-size: 14px;
    text-align: center;
  }


}

.assc-loc {
  &__dealer-status-active {
    margin-top: 6px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: $green;
    padding-right: 10px;
  }

  &__dealer-status-inactive {
    margin-top: 6px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: $grey-dark;
    padding-right: 10px;
  }

  &__user-location-status {
    border-radius: 20px;
    display: flex;
  }

  &__location-status-text {
    padding-left: 10px;
  }

}
