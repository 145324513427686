@import "~@carfax-stencils/styles/src/colors.scss";

.processing-api-call-spinner-div {
  z-index: 100;
  display: block;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  padding-right: 40%;
}

.processing-api-call-spinner {
  margin-left: 65%;
}