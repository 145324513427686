@import "~@carfax-stencils/styles/src/colors.scss";

.inline-error-div {
  padding-top: 5px;
  padding-left: 5px;
}

.inline-error-text {
  color: red;
}
