@import "~@carfax-stencils/styles/src/colors.scss";
@import '@carfax-stencils/styles/src/vars.scss';

.page_wrapper {

  background-color: $grey-lightest;

  .page {
    &__frame {
      width: 100%;

      > .noPrint {
        position: relative;
        z-index: 20;
      }
    }

    &__wrapper {
      flex-basis: 80%;
    }

    &__content {
      max-width: 100%;
      margin: auto;
      min-height: 360px;
    }

    &__content-full-width {
      width: 100%;
      min-height: 360px;
    }

    &__heading {
      font-size: 30px;
      font-weight: bold;
      height: 40px;
      letter-spacing: 0;
      line-height: 40px;
      padding-bottom: 30px;
      padding-left: 24px;
      width: 100%;
    }

    &__data {
      background-color: $white;
      border: 1px solid #e0e0e0;
      border-bottom-color: #bdbdbd;
      border-radius: 5px;
      margin-right: 24px;
      padding-left: 20px;
      padding-top: 20px;
      padding-right: 20px;
      margin-bottom: 5px;
      flex-basis: 80%;
    }

    &__no-data {
      background-color: $white;
      border: 1px solid #e0e0e0;
      border-bottom-color: #bdbdbd;
      border-radius: 5px;
      color: $grey-dark;
      flex-basis: 80%;
      font-size: 20px;
      font-weight: bold;
      height: 700px;
      margin-right: 24px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 200px;
      text-align: center;
    }
  }

  .page-notification-div {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 15px;
  }

}

@media print {
  .page_wrapper {
    padding-top: 0;

    .page {
      &__frame {
        width: 100%;
      }

      &__content {
        max-width: 1280px;
        margin: auto;
        min-height: 360px;
      }

      &__content-full-width {
        width: 100%;
        min-height: 360px;
      }
    }
  }
}
