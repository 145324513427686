@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.no-results {
  padding: 10px 0px 24px;
  text-align: center;

  &__img {
    margin-bottom: 34px;
  }

  &__subtitle {
    @extend %text-body-large;
    color: $grey-dark;
    margin-bottom: 100px;
    font-weight: bold;
    font-size: 20px;
  }
}
