@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.bulk-invite {

  &__layout-wrapper {
    background-color: white;
    border-color: $grey-light;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    flex-direction: row;
    height: 485px;
    margin-top: 20px;
    margin-left: 32%;
    position: absolute;
    width: 600px;
  }

  &__content-div {
    display: block;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 900px;
  }

  &__input-div {
    padding-left: 20px;
    padding-top: 30px;
    padding-right: 40px;
  }

  &__file-uploader-div {
    position: absolute;
    padding-top: 30px;
    padding-left: 15px;
  }

  &__description-text {
    padding-left: 15px;
    padding-right: 40px;
    color: $grey-darkest;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    letter-spacing: 0;
  }

  &__title-text {
    padding-left: 15px;
    padding-right: 40px;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
  }

  &__upload-heading {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    color: $blue;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    letter-spacing: 0;
  }
}