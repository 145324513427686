@import "~@carfax-stencils/styles/src/colors.scss";

.dam-header {
  height: 64px;
  background-color: $blue;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24);

  a {
    color: $white;
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: $white;
      cursor: pointer;
      text-decoration: none;
    }
  }
  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .dam-container {
    display: flex;
    justify-content: space-between;
  }

  .dam-container-logo {
    padding-left: 20px;
    display: flex;
    font-size: 14px;
    flex-shrink: 0;
    text-align: center;
  }

  .dam-container-nav {
    width: calc(180px - 32px);
    padding: 20px 5px;
    display: flex;
    font-size: 14px;
    flex-shrink: 0;
    text-align: center;

    &-right {
      width: calc(160px - 80px);
      padding: 20px 16px;
      display: flex;
      font-size: 14px;
      flex-shrink: 0;
      margin-left: auto;
    }
  }

  .hover-select:hover {
    background-color: $blue-dark;
  }

  .dam-container-nav-separator-line {
    height: 62px;
    border-left: solid;
    border-color: #1d44b9;
    border-width: thin;
  }

  .dam-container-nav-profile {
    width: calc(180px - 65px);
    padding: 20px 5px;
    display: flex;
    font-size: 14px;
    flex-shrink: 0;
    text-align: center;
  }

  .dam-container-nav-account-mgmt {
    width: calc(240px - 65px);
    padding: 20px 5px;
    display: flex;
    font-size: 14px;
    flex-shrink: 0;
    text-align: center;
  }

  .dam-container-nav-partner-maintenance {
    width: calc(180px - 20px);
    padding: 20px 5px;
    display: flex;
    font-size: 14px;
    flex-shrink: 0;
    text-align: center;
  }

  .icon-logo {
    vertical-align: middle;
    padding-bottom: 2px;
    padding-left: 10px;
  }
}
