@import "~@carfax-stencils/styles/src/colors.scss";

.confirmation-modal {

  &__header {
    font-size: large;
    font-weight: bold;
  }

  &__message {
    margin-top: 5px;
  }

  &__footer {
    margin-top: auto;

    button {
      //width: 50%;

      &:first-child {
        //background-color: $blue;
        //margin-bottom: 12px;
      }
    }
  }
}
