@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.c4l-prefs-tab-div {
  background-color: $white;
  flex-basis: 80%;
  height: 100%;
  margin-right: 24px;
  padding-top: 20px;
  padding-right: 5px;
}

.c4l-prefs-tab-layout {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;
  height: 100%;
}

.c4l-blue-sub-heading {
  font-size: 25px;
  color: $blue;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.c4l-accordion-expander {
  padding-bottom: 25px;
}

.c4l-accordion-body-div {
  margin-right: 20px;
}

.c4l-accordion-spacer-div {
  padding-bottom: 30px;
}

.provider-input-width {
  width: 500px;
}

.customer-list-load-date {
  padding-top: 5px;
  padding-left: 5px;
  width: 200px;
}

.til-color-blue {
  color: $blue;
}

.til-toggles-div {
  height: 420px;
  width: 750px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  background-color: #F5F5F5;
}

.til-toggles-left-padding {
  padding-left: 25px;
}

.til-toggles-description-left-padding {
  padding-left: 65px;
}

.til-widget-msg-left-padding {
  padding-left: 90px;
}

.til-toggles-bold {
  font-weight: bold;
}

.til-toggles-separator-line {
  width: 690px;
  color: $grey-light;
  border-top: solid;
  border-left: solid;
  border-width: thin;
  margin-left: 25px;
}

.til-toggles-sub-toggles-heading {
  padding-left: 20px;
  padding-bottom: 5px;
}




