@import "~@carfax-stencils/styles/src/colors.scss";
@import "~@carfax-stencils/styles/src/typography.scss";

.reputation-partner-layout-search-wrapper-div {
  overflow-y: scroll;
  height: 100%;
  width: 440px;
  min-width: 440px;
}

.reputation-partner-layout-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;
  position: absolute;
  height: 3200px;
  width: 100%;
}

.reputation-partner-layout-tabs {
  border-top: hidden;
}

.reputation-partner-layout-message {
  font-weight: bold;
  padding-bottom: 10px;
  padding-right: 25px;
}

.reputation-partner-page-with-data {
  height: 473px;
  margin-bottom: 10px;
  margin-right: 24px;
}

.reputation-partner-layout-heading {
  color: $black;
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  letter-spacing: 0;
  line-height: 40px;
  padding-bottom: 60px;
  padding-left: 5px;
  padding-top: 5px;
  width: 100%;
}

.reputation-partner-select-input-heading {
  color: $black;
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  padding-left: 5px;
  width: 100%;
}

.reputation-partner-select-input-div {
  padding-left: 5px;
  width: 35%;
}

.reputation-partner-checkbox-input-div {
  padding-left: 5px;
  padding-top: 25px;
  width: 60%;
}

.reputation-partner-checkbox-note-div {
  margin-left: 37px;
  margin-top: 3px;
}

.reputation-partner-checkbox-note {
  font-weight: bold;
  font-style: italic;
}

.reputation-partner-button-row-div {
  display: flex;
  justify-content: left;
}

.reputation-partner-button-div {
  padding-left: 5px;
  padding-right: 10px;
  padding-top: 40px;
}

.reputation-partner-spinner-div {
  padding-top: 25px;
  padding-left: 90px;
}
